<template>
    <div>
        <card-header title="Trip Add-Ons" :subheader="trip.title" icon="fa-layer-plus"/>
        <card-toolbar>
            <button @click="$openCard('new-trip-addon', {tripId: props.tripId}, card)"><i class="fas fa-plus mr-2"/>New Add-On</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <template v-if="props.type === 'departure'">
                    <subheader-list-item title="Departures" icon="fas fa-layer-plus" :badge="addOnByType('departure').length"/>
                    <trip-add-on-list-item
                        :card="card"
                        :addon="addon"
                        v-for="addon in addOnByType('departure')"
                        :active="child && child.definition.component === 'trip-addon' && child.props.addonId === addon.id.toString()"
                        @click="$openCard('trip-addon', {addonId: addon.id}, card)"
                    />
                </template>

                <template v-if="props.type === 'extensions'">
                    <subheader-list-item title="Extensions" icon="fas fa-layer-plus" :badge="addOnByType('extensions').length"/>
                    <trip-add-on-list-item
                        :card="card"
                        :addon="addon"
                        v-for="addon in addOnByType('extensions')"
                        :active="child && child.definition.component === 'trip-addon' && child.props.addonId === addon.id.toString()"
                        @click="$openCard('trip-addon', {addonId: addon.id}, card)"
                    />
                </template>

                <template v-if="props.type === 'airfare'">
                    <subheader-list-item title="Airfare" icon="fas fa-layer-plus" :badge="addOnByType('airfare').length"/>
                    <trip-add-on-list-item
                        :card="card"
                        :addon="addon"
                        v-for="addon in addOnByType('airfare')"
                        :active="child && child.definition.component === 'trip-addon' && child.props.addonId === addon.id.toString()"
                        @click="$openCard('trip-addon', {addonId: addon.id}, card)"
                    />
                </template>

                <template v-if="props.type === 'aircredit'">
                    <subheader-list-item title="Airfare Credit" icon="fas fa-layer-plus" :badge="addOnByType('aircredit').length"/>
                    <trip-add-on-list-item
                        :card="card"
                        :addon="addon"
                        v-for="addon in addOnByType('aircredit')"
                        :active="child && child.definition.component === 'trip-addon' && child.props.addonId === addon.id.toString()"
                        @click="$openCard('trip-addon', {addonId: addon.id}, card)"
                    />
                </template>

                <template v-if="props.type === 'accomodations'">
                    <subheader-list-item title="Accommodations" icon="fas fa-layer-plus" :badge="addOnByType('accomodations').length"/>
                    <trip-add-on-list-item
                        :card="card"
                        :addon="addon"
                        v-for="addon in addOnByType('accomodations')"
                        :active="child && child.definition.component === 'trip-addon' && child.props.addonId === addon.id.toString()"
                        @click="$openCard('trip-addon', {addonId: addon.id}, card)"
                    />
                </template>

                <template v-if="props.type === 'tennis'">
                    <subheader-list-item title="Tennis" icon="fas fa-layer-plus" :badge="addOnByType('tennis').length"/>
                    <trip-add-on-list-item
                        :card="card"
                        :addon="addon"
                        v-for="addon in addOnByType('tennis')"
                        :active="child && child.definition.component === 'trip-addon' && child.props.addonId === addon.id.toString()"
                        @click="$openCard('trip-addon', {addonId: addon.id}, card)"
                    />
                </template>

                <template v-if="props.type === 'other'">
                    <subheader-list-item title="Other" icon="fas fa-layer-plus" :badge="addOnByType('other').length"/>
                    <trip-add-on-list-item
                        :card="card"
                        :addon="addon"
                        v-for="addon in addOnByType('other')"
                        :active="child && child.definition.component === 'trip-addon' && child.props.addonId === addon.id.toString()"
                        @click="$openCard('trip-addon', {addonId: addon.id}, card)"
                    />
                </template>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardList from "@/TIER/components/CardList";
    import CardToolbar from "@/TIER/components/CardToolbar";
    import SearchListItem from "@/TIER/components/SearchListItem";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import SeparatorListItem from "@/TIER/components/SeparatorListItem";
    import NavigationListItem from "@/TIER/components/NavigationListItem";

    import {client as http} from '@/http_client';
    import CardBody from "@/TIER/components/CardBody";
    import TripAddOnListItem from "@/components/TripAddOnListItem";

    export default {
        props: ['card', 'child', 'props'],
        components: {TripAddOnListItem, CardBody, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                addons: [],
                trip: {}
            };
        },
        methods: {
            loadAddOns: function(force) {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId + '/addons/0/500', {force}).then(response => {
                    this.addons = response.data.addons;
                    this.$emit('loading', false);
                });
            },
            loadTrip: function() {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId).then(response => {
                    this.trip = response.data;
                });
            },
            addOnByType: function(type) {
                let arr = [];
                for (let i = 0; i < this.addons.length; i++) {
                    if (this.addons[i].type === type) arr.push(this.addons[i]);
                }
                return arr.sort(function(a, b) {
                    let textA = a.title.toUpperCase();
                    let textB = b.title.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
            },
            reload: function() {
                this.loadAddOns(true);
            }
        },
        watch: {
            'props': function() {
                this.loadAddOns();
            }
        },
        mounted() {
            this.loadAddOns();
            this.loadTrip();
        }
    };
</script>
